import React from "react";
import { LocalizeText } from "components/localizer";
import { ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import ListInlineEditWrapper from "../listComponents/listInlineEditWrapper";
import { IProduct, IProductRule, Unit, unitTypes } from "reducers/products";
import { TLanguageTag } from "contexts/language";
import { disableWheelScroll } from "utils/helpers";

interface IProps {
    tag: TLanguageTag;
    product: IProduct;
    numberProp: keyof IProductRule;
    unitProp: keyof IProductRule;
    onSubmitUpdate: (props: Record<string, unknown>) => void;
    canEdit?: boolean;
}

const EditableRuleField: React.FC<IProps> = ({
    tag,
    product,
    numberProp,
    unitProp,
    onSubmitUpdate,
    canEdit = true,
}) => {
    const [text, setText] = React.useState<string>((product.rules?.[numberProp] as string) || "");
    const [unit, setUnit] = React.useState<string>((product.rules?.[unitProp] as string) || unitTypes[1]);

    if (!canEdit) {
        return (
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag={tag} />}
                    secondary={
                        product.rules?.[numberProp] ? (
                            <>
                                {product.rules[numberProp] as string}{" "}
                                <LocalizeText tag={product.rules[unitProp] as TLanguageTag} />
                            </>
                        ) : (
                            "-"
                        )
                    }
                />
            </ListItem>
        );
    }

    return (
        <ListInlineEditWrapper
            onChange={(e) => {
                const newValue = e.target.value;
                if (unitTypes.includes(newValue as Unit)) {
                    setUnit(newValue);
                } else {
                    setText(newValue);
                }
            }}
            onSubmit={() => onSubmitUpdate({ rules: { [numberProp]: text, [unitProp]: unit } })}
            onCancel={() => {
                setText((product.rules?.[numberProp] as string) || "");
                setUnit((product.rules?.[unitProp] as string) || unitTypes[1]);
            }}
            label={<LocalizeText tag={tag} />}
            renderInput={(props) => (
                <>
                    <TextField
                        {...props}
                        value={text}
                        type="number"
                        inputProps={{ min: 0 }}
                        onFocus={disableWheelScroll}
                    />
                    <TextField {...props} label={undefined} value={unit} select>
                        {unitTypes.map((unit) => {
                            return (
                                <MenuItem key={unit} value={unit}>
                                    <LocalizeText tag={unit} />
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </>
            )}
            renderView={(props) => (
                <ListItemText
                    {...props}
                    secondary={
                        product.rules?.[numberProp] ? (
                            <>
                                {product.rules[numberProp] as string}{" "}
                                <LocalizeText tag={product.rules[unitProp] as TLanguageTag} />
                            </>
                        ) : (
                            "-"
                        )
                    }
                />
            )}
            inputIsValid={parseInt(text) >= 0}
        />
    );
};

export default EditableRuleField;
