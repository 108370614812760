import React from "react";
import { DialogActions, DialogContent, DialogTitle, Divider, Stack, TextField } from "@mui/material";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import { LocalizeText } from "components/localizer";
import { DatePicker } from "components/datePicker";
import { dateFormats } from "utils/formats";
import { format, isValid as isValidDate } from "date-fns";
import validator from "utils/validator";
import CommunityContext from "contexts/community";
import { createFileDownloadFromBlob, disableWheelScroll } from "utils/helpers";

interface IState {
    startDate: Date | null;
    offset: number;
    fileName: string;
}

const CreateCostCalculationRunDialog: React.FC = () => {
    const { popDialog } = React.useContext(ModalContext);
    const { addCostCalculation, isAddingCostCalculation } = React.useContext(CommunityContext);
    const [state, setState] = React.useState<IState>({
        startDate: null,
        offset: 0,
        fileName: `cost-calculations-${format(Date.now(), dateFormats.WEBDATE)}.xlsx`,
    });

    const onClick = async (): Promise<void> => {
        if (state.fileName && state.startDate) {
            const blob = await addCostCalculation({
                start_date: format(state.startDate, dateFormats.APIDATE),
                offset: state.offset,
                file_name: state.fileName,
            });
            if (blob) {
                createFileDownloadFromBlob(blob, state.fileName);
                popDialog();
            }
        }
    };

    const onChange = (type: keyof IState, value: string | number | Date): void => {
        setState({ ...state, [type]: value });
    };

    const isValid = (): boolean => {
        return !!state.fileName && state.offset >= 0 && isValidDate(state.startDate);
    };

    return (
        <>
            <DialogTitle>
                <LocalizeText tag="newCostCalculation" />
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ width: "600px" }}>
                <Stack>
                    <DatePicker
                        disablePast
                        format={dateFormats.WEBDATE}
                        label={<LocalizeText tag="startDate" />}
                        value={state.startDate}
                        slotProps={{ textField: { variant: "outlined" } }}
                        onChange={(value: Date) => onChange("startDate", value as Date)}
                    />
                    <TextField
                        required
                        margin="normal"
                        label={<LocalizeText tag="offset" />}
                        value={state.offset}
                        type="number"
                        onChange={(event) => onChange("offset", event.target.value)}
                        helperText={<LocalizeText tag="offsetExplaination" />}
                        onFocus={disableWheelScroll}
                    />
                    <TextField
                        required
                        margin="normal"
                        label={<LocalizeText tag="fileName" />}
                        value={state.fileName}
                        onChange={(event) => onChange("fileName", event.target.value)}
                        error={!validator.fileName(state.fileName, ["xlsx"])}
                        helperText={".xlsx"}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <VKButton tag="close" template="cancel" onClick={() => popDialog()} />
                <VKButton
                    tag="createAndDownload"
                    template="save"
                    onClick={onClick}
                    disabled={!isValid()}
                    isLoading={isAddingCostCalculation}
                />
            </DialogActions>
        </>
    );
};

export default CreateCostCalculationRunDialog;
